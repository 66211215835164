#root {
  /* margin-left: 100px;
    margin-right: 100px; */
  /* background-color:  #0072bb; */
}

ul#nav li a {
  color: black;
}

.aboutImageContainer {
  width: 30%;
}

.management-services-ul li {
  color: white;
}

.amazon-pillpack .amazon-logo {
  width: 20%;
}

.amazon-pillpack-ol li {
  color: #00b0f0;
}

.paperbag-ul li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

.create-value-ul {
  list-style-type: disc;
}

.create-value-ul li {
  color: black;
}
