.row banner {
}

.aims-home-container-header {
  position: relative;
  text-align: center;
  //   color: white;
  .banner-container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white !important;
    width: 80%;
  }
  .aims-responsive-headline {
    color: white;
    font-size: 72px;
  }
}

.aims-values {
  p {
    font-size: 22px;
  }
}
