.aims-active-link {
  color: blue;
}

.aims-nav {
  // font-size: 18px;
  // background-image: linear-gradient(260deg, #2376ae 0%, #c16ecf   100%);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;

  // background-color: #525a9b;
  background-color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 100px;

  .aims-nav-logo img {
    max-width: 65%;
  }
  .aims-nav-menu-ul {
    display: flex;
    li {
      margin: 0px 10px;
      a {
        color: black;
      }
    }
  }
}

.aims-nav-router-container {
  font-family: open-sans, sans-serif;
  font-style: normal;
  //   margin-bottom: 15px;
}

.custom-margin-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.aims-footer {
  height: 100px;
  //   margin-top: 15px;
  background-color: white;
}
