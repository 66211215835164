$main_font: 'Roboto';
$main_color: #ff6600;

.aims-card{
    // padding: 15px;
    margin-top: 15px;
    margin-left: 7.5px;
    margin-right: 7.5px;
    ul {
        font-family: $main_font;
        // font-size: 13px;
        line-height: 1.5em;
        margin: 5px 0 15px;
        padding: 0;
        li {
            list-style: none;
            position: relative;
            padding: 0 0 0 20px;
        }
    }

    ul.aims-cards-ul{
        li {
            &::before {
                content: "" ;
                position: absolute ;
                left: 0 ;
                top: 6px ;
                height: 5px ;
                width: 5px ;
                border: 1px solid $main_color ;
                border-width: 2px 2px 0 0 ;
                transform: rotate(45deg) ;
            }
        }
    }
}
