.contactus-header-image {
  position: relative;
  text-align: center;
  color: white;
  img {
    // opacity: 0.6;
  }
  .contact-us-header {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.contact-us-container {
  background-color: #3b5999;
  height: 300px;
}

.contact-us-address-container {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  padding-top: 90px;
  color: white;
  .contact-us-address-container-child {
    text-align: center;
  }
}

.contact-us-map-container {
  background-color: #3b5999;
  height: 300px;
  padding: 0px 50px;
  padding-bottom: 15px;
}
