// .client-header-container {
//   // height: 300px;
//   // background-color: #0c122a;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   h1 {
//     color: white;
//   }
// }

.client-header-container {
  position: relative;
  text-align: center;
  color: white;
  img {
    width: 100%;
  }
  .client-header {
    position: absolute;
    top: 45%;
    left: 47%;
    transform: translate(-50%, -50%);
    color: black;
  }
}

.client-container-images {
  padding: 15px 100px;
  background: #3b5999;
  // padding-top: 15px;
}

.aims-customer-container {
  display: grid;
  background: none;
  background-color: none;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
  .customer-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    image {
      width: fit-content;
      height: fit-content;
      float: left;
      // width: 100px;
      // height: 100px;
      object-fit: cover;
    }
  }
}

.services-header-image {
  position: relative;
  text-align: center;
  color: white;
  // margin-top: 10px;
  img {
    // opacity: 0.6;
  }
  .our-service-header {
    position: absolute;
    top: 45%;
    left: 47%;
    transform: translate(-50%, -50%);
    color: black;
  }
}
