.aboutus-header-image {
  position: relative;
  text-align: center;
  color: white;
  .aboutus-header-label {
    position: absolute;
    top: 45%;
    left: 47%;
    transform: translate(-50%, -50%);
    color: black;
  }
}

.aims-about-us {
  display: flex;
  background: #3b5999;
  color: black;
  padding: 50px 100px;

  .about-us-info {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    padding: 15px;
    border-radius: 50px;
    h3 {
      color: black;
    }
    .about-us-info-image {
      width: auto;
      height: 250px;
      border-radius: 126px;
    }
    .about-us-info-points {
    }
  }

  .about-us-award {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-left: 15px;
    padding: 15px;
    border-radius: 50px;
    h3 {
      color: black;
    }
    .about-us-award-image {
      width: auto;
      height: 250px;
    }
    .about-us-award-info {
    }
  }
}
