.services-header-image {
  position: relative;
  text-align: center;
  color: white;
  .our-service-header {
    position: absolute;
    top: 45%;
    left: 47%;
    transform: translate(-50%, -50%);
    color: black;
  }
}

// .our-service-header{
//     text-align: center;
//     line-height: 400px;
// }

.aims-card-container {
  padding: 0px 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #3b5999;
}

.aims-card-container-2 {
  padding: 15px 100px;
  background: #3b5999;
}

.aims-card-container-2 > *:first-child {
  margin-top: 0px;
}
