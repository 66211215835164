.case-studies-header-image {
  position: relative;
  text-align: center;
  color: white;
  .case-studies-header-label {
    position: absolute;
    top: 45%;
    left: 47%;
    transform: translate(-50%, -50%);
    color: white;
  }
}
